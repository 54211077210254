import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  CamelToHumanPipe,
  DaysHoursSecondsPipe,
  BytesConvertFilterPipe,
  EpochToDateFilterPipe,
  StrToMacPipe,
  SafePipe,
  ArrayOrderByPipe,
  FormatTrafficUnitsPipe,
  DateAgoPipe,
  FormatCellPipe,
  UniqueFilterPipe,
  ArrayToStrPipe,
  TimeFormat,
  DateFormat,
  DateAndTimeFormat,
  CveToDateFilterPipe,
  MacFilterPipe,
  AssessmentDateFilterPipe,
  SplitPipe,
  FilterPipe,
  ArrayToStrEllipsisPipe,
  SearchPipe,
  SafeHTMLPipe,
  TimeAgoPipe,
  ArraySortPipe,
  EllipsisPipe,
  ShortNumberPipe,
  UtcToLocale,
  FileNameFilterPipe,
  MaskPasswordFilterPipe,
  ArrayToObjWithEllipsisPipe,
  DateToDaysFilterPipe,
  EnumFilterPipe,
  NoneToHyphen,
  UtcTimeAgoPipe,
  UppercasePipe,
  Iso8601ToLocalTimePipe,
  CleanStringPipe,
  EnumStringPipe,
  TagsPipe, ManualTagsPipe,
  ToFixedPipe,
  TTimePipe,
  DecodeHtmlStrPipe,
  UtcToLocaleRDate,
  KeyTransformPipe,
    RemeActionPipe,
    ObjectToTimeFilterPipe,
    TextBadgePipe,
} from './app.filter.pipe';

@NgModule({
  declarations: [
    CamelToHumanPipe,
    KeyTransformPipe,
    DaysHoursSecondsPipe,
    BytesConvertFilterPipe,
    EpochToDateFilterPipe,
    StrToMacPipe,
    SafePipe, MacFilterPipe, ArrayOrderByPipe, AssessmentDateFilterPipe, SplitPipe, ArrayToStrEllipsisPipe, SearchPipe,
    FormatTrafficUnitsPipe, SafeHTMLPipe, TimeAgoPipe, ArraySortPipe, EllipsisPipe, ShortNumberPipe, UtcToLocale,
    FileNameFilterPipe, MaskPasswordFilterPipe, ArrayToObjWithEllipsisPipe, DateToDaysFilterPipe, EnumFilterPipe,
    NoneToHyphen, UtcTimeAgoPipe, UppercasePipe, Iso8601ToLocalTimePipe,ObjectToTimeFilterPipe,
    DateAgoPipe, CleanStringPipe, ToFixedPipe, TTimePipe, DecodeHtmlStrPipe, EnumStringPipe, TagsPipe, ManualTagsPipe,
    FormatCellPipe,
    UniqueFilterPipe,
    ArrayToStrPipe, RemeActionPipe,
    TimeFormat,
    DateFormat,
    DateAndTimeFormat,
    CveToDateFilterPipe, FilterPipe, UtcToLocaleRDate,
      TextBadgePipe
  ],
  imports: [CommonModule],
  exports: [
    CamelToHumanPipe,
    DaysHoursSecondsPipe,
    BytesConvertFilterPipe,
    EpochToDateFilterPipe,
    StrToMacPipe, KeyTransformPipe,
    SafePipe, MacFilterPipe, ArrayOrderByPipe, AssessmentDateFilterPipe, SplitPipe, ArrayToStrEllipsisPipe, SearchPipe,
    FormatTrafficUnitsPipe, SafeHTMLPipe, TimeAgoPipe, ArraySortPipe,
    FileNameFilterPipe, MaskPasswordFilterPipe, ArrayToObjWithEllipsisPipe, DateToDaysFilterPipe, EnumFilterPipe,
    NoneToHyphen, UtcTimeAgoPipe, UppercasePipe, Iso8601ToLocalTimePipe,ObjectToTimeFilterPipe,
    DateAgoPipe, CleanStringPipe, ToFixedPipe, TTimePipe, DecodeHtmlStrPipe, EnumStringPipe, TagsPipe, ManualTagsPipe,
    FormatCellPipe,
    UniqueFilterPipe,
    ArrayToStrPipe,
    TimeFormat,
    DateFormat,
    DateAndTimeFormat,
    CveToDateFilterPipe, RemeActionPipe,
    FilterPipe, EllipsisPipe, ShortNumberPipe, UtcToLocale, UtcToLocaleRDate,ObjectToTimeFilterPipe,
      TextBadgePipe
  ],
  providers: [DatePipe, FilterPipe, CleanStringPipe, EnumStringPipe, TagsPipe, ManualTagsPipe, DateAgoPipe, BytesConvertFilterPipe, ArrayOrderByPipe, SearchPipe, EnumFilterPipe]
})
export class AppFilterPipeModule {
}
