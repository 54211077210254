import { Component, HostListener } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { LoaderService } from "./_services/loader.service";
import { MatButtonModule } from "@angular/material/button";
import { NgIf, NgOptimizedImage } from "@angular/common";
import { AsyncPipe } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [RouterOutlet, MatButtonModule, NgIf, AsyncPipe, NgOptimizedImage],
})
export class AppComponent {
  contentText: any;
  showLoader = new BehaviorSubject<boolean>(false);
  loading$ = this.showLoader.asObservable();
  result: any;
  /**
   * Constructor
   */
  constructor(private loaderService: LoaderService) {
    this.loaderService.status.subscribe((val: boolean, text: string) => {
      this.result = val;
      setTimeout(() => {
        this.showLoader.next(this.result.value);
      });
      this.contentText = this.result.text;
    });
  }
  ngOnInit(): void {}

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.loaderService.display(false);
      this.loaderService.Modeldisplay(false);
    }
  }
}
