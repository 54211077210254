import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { UserService } from 'app/core/user/user.service';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from 'app/mock-api/common/navigation/data';
import cloneDeep from 'lodash-es/cloneDeep';
import {of} from "rxjs";
import jwt_decode from "jwt-decode";

@Injectable({providedIn: 'root'})
export class NavigationMockApi
{
    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    cn: FuseNavigationItem[] = compactNavigation;
    dn: FuseNavigationItem[] = defaultNavigation;
    fn: FuseNavigationItem[] = futuristicNavigation;
    hn: FuseNavigationItem[] = horizontalNavigation;

    roleMapping: any = {
        activedirectoryviewer: 4,
        activedirectorywriter: 4,
        approver: 1,
        assetviewer: 1,
        assetwriter: 1,
        complianceviewer: 3,
        compliancewriter: 3,
        piiviewer: 5,
        piiwriter : 5,
        vulnerabilityviewer: 2,
        vulnerabilitywriter: 2
    }
    objectKeys = Object.keys;
    currentRoles: any = [];

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService, private uS: UserService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        var token = localStorage.getItem('zitadel:id_token');
        if (!token) {
            setTimeout(() => this.registerHandlers());
            return;
        }
        this.uS.userd$ = of(jwt_decode(token));
        this.uS._user_roles = Object.keys(jwt_decode(token)["urn:zitadel:iam:org:project:roles"]);
        if (this.uS._user_roles.indexOf('admin') === -1
            && this.uS._user_roles.indexOf('customer') === -1) {
            this.uS._user_roles.forEach((obj: any) => {
                if (this.currentRoles.indexOf(this.roleMapping[obj]) === -1) {
                    this.currentRoles.push(this.roleMapping[obj]);
                }
            });
            const c: any = {}; const f: any = {}; const d: any = {}; const h: any = {};
            c[0] = cloneDeep(this._compactNavigation[0]);
            f[0] = cloneDeep(this._futuristicNavigation[0]);
            h[0] = cloneDeep(this._horizontalNavigation[0]);
            d[0] = cloneDeep(this._defaultNavigation[0]);
            this.currentRoles.forEach((role: any) => {
                c[role] = cloneDeep(this._compactNavigation[role]);
                f[role] = cloneDeep(this._futuristicNavigation[role]);
                h[role] = cloneDeep(this._horizontalNavigation[role]);
                d[role] = cloneDeep(this._defaultNavigation[role]);
            });
            this.cn = []; this.hn = []; this.fn = []; this.dn = [];
            this.objectKeys(c).forEach((k: any) => {
                this.cn.push(c[k]);
            });
            this.objectKeys(f).forEach((k: any) => {
                this.fn.push(f[k]);
            });
            this.objectKeys(h).forEach((k: any) => {
                this.hn.push(h[k]);
            });
            this.objectKeys(d).forEach((k: any) => {
                this.dn.push(d[k]);
            });
        }



        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/common/navigation')
            .reply(() =>
            {
                // Fill compact navigation children using the default navigation
                this.cn.forEach((compactNavItem) =>
                {
                    this.dn.forEach((defaultNavItem) =>
                    {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this.fn.forEach((futuristicNavItem) =>
                {
                    this.dn.forEach((defaultNavItem) =>
                    {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this.hn.forEach((horizontalNavItem) =>
                {
                    this.dn.forEach((defaultNavItem) =>
                    {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this.cn),
                        default   : cloneDeep(this.dn),
                        futuristic: cloneDeep(this.fn),
                        horizontal: cloneDeep(this.hn),
                    },
                ];
            });
    }
}
