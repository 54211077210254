import { AuthenticationService } from 'app/services/authentication.service';
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { from, of } from 'rxjs';
import {OAuthService} from "angular-oauth2-oidc";

export const AuthGuard: CanActivateFn | CanActivateChildFn = () => {
    // const auth: AuthenticationService = inject(AuthenticationService);
    // const oauth: OAuthService = inject(OAuthService);
    // if (!oauth.hasValidAccessToken()) {
    //     return from(auth.authenticate());
    // } else {
    //     if (!auth.authenticated) {
    //         auth.updateAuth(oauth.hasValidAccessToken());
    //     }
    // }
    // return of(auth.authenticated);
    const auth: AuthenticationService = inject(AuthenticationService);
    console.log("auth.authenticated-->",auth.authenticated)
    return of(auth.authenticated);
};
