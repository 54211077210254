<div
  class="fixed lg:sticky sm:sticky md:sticky xs:sticky top-0 bottom-0 w-16 h-screen shadow"
>
  <div
    class="flex flex-col w-full sm:w-16 h-full transition-transform duration-400 ease-drawer bg-card"
  >
    <div
      class="flex-0 w-16 h-full overflow-y-auto overscroll-y-contain sm:overflow-hidden sm:overscroll-auto"
      fuseScrollbar
      [fuseScrollbarOptions]="{ wheelPropagation: true }"
    >
      <div
        class="flex items-center justify-center w-full h-14 border-b text-lg uppercase"
      >
        <!--<mat-icon [svgIcon]="'tune'"></mat-icon> -->
        <settings></settings>
      </div>
      <div class="flex-auto">
        <ng-container>
          
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Select action or start new template -->
<ng-template #selectChatOrStartNew>
  <div
    class="flex flex-col flex-auto items-center justify-center w-full h-full p-4"
  >
    <mat-icon
      class="icon-size-24"
      [svgIcon]="'heroicons_outline:cube-transparent'"
    ></mat-icon>
    <div
      class="mt-4 text-xl text-center font-medium tracking-tight text-secondary"
    >
      Select an action
    </div>
  </div>
</ng-template>

<!-- Speech bubble tail SVG -->
<!-- @formatter:off -->
<ng-template #speechBubbleExtension>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z"
        fill="currentColor"
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
</ng-template>
<!-- @formatter:on -->


<!-- [ngClass]="{
  'sm:w-3/4 sm:min-w-3/4': allowedId.indexOf(selectedAction?.id) === -1,
  'sm:w-[40%] sm:min-w-[40%]': allowedId.indexOf(selectedAction?.id) > -1
}" -->
<fuse-drawer
  class="w-screen min-w-screen z-999 fixed sm:w-3/4 sm:min-w-3/4"
  [mode]="'over'"
  [name]="'dynamicSettingsDrawer'"
  [position]="'right'"
  #dynamicSettingsDrawer
>
  <div class="flex flex-col w-full bg-card">
  </div>
</fuse-drawer>
