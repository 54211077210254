import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { FuseConfig, FuseConfigService, Scheme, Theme, Themes, Views } from '@fuse/services/config';
import { Subject, takeUntil } from 'rxjs';
import { FuseScrollResetDirective } from "../../../../@fuse/directives/scroll-reset";
import { CommonService } from "../../../_services/common.service";
import { BaseRequestService } from 'app/_services/base.service';
import { LoaderService } from 'app/_services/loader.service';
import { MyToastrService } from 'app/_services/toastr.service';
import { DirectivesModule } from 'app/-directives/-directives.module';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styles: [
        `
            settings {
                position: static;
                display: block;
                flex: none;
                width: auto;
            }

            @media (screen and min-width: 1280px) {

                empty-layout + settings .settings-cog {
                    right: 0 !important;
                }
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatIconModule, DirectivesModule, FuseDrawerComponent, MatButtonModule, NgFor, NgClass, MatTooltipModule, NgIf, FuseScrollbarDirective, FuseScrollResetDirective],
})
export class SettingsComponent implements OnInit, OnDestroy {
    config: FuseConfig;
    layout: string;
    scheme: 'dark' | 'light';
    theme: string;
    themes: Themes;
    views: Views;
    view: string;
    defaultConfig: any = {};
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _fuseConfigService: FuseConfigService,
        private cs: CommonService,
        private _bs: BaseRequestService,
        private _changeDetectorRef: ChangeDetectorRef,
        private loaderService: LoaderService,
        public toast: MyToastrService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config: FuseConfig) => {
                // Store the config
                this.config = config;
            });
        if (this.cs.sConfig && !this.cs.sConfig.id) {
            setTimeout(() => this.getLayoutSettings(), 2000);
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the layout on the config
     *
     * @param layout
     */
    setLayout(layout: string): void {
        // Clear the 'layout' query param to allow layout changes
        this._router.navigate([], {
            queryParams: {
                layout: null,
            },
            queryParamsHandling: 'merge',
        }).then(() => {
            // Set the config
            this._fuseConfigService.config = { layout };
        });
    }

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
    setScheme(scheme: Scheme): void {
        this._fuseConfigService.config = { scheme };
        if (scheme === 'light') {
            this.cs.logo = '/assets/images/logo/cs_logo_light.png';
        } else {
            this.cs.logo = '/assets/images/logo/cs_logo_dark.png';
        }
    }

    /**
     * Set the theme on the config
     *
     * @param theme
     */
    setTheme(theme: Theme): void {
        this._fuseConfigService.config = { theme };
    }

    setView(view: any): void {
        this._fuseConfigService.config = { view }
    }


    saveSetting(): void {
        this.cs.sConfig.value = this.config;
        this.cs.sConfig.name = this._bs.user().email;
        const request: any = { data: this.cs.sConfig };
        if (this.cs.sConfig.id) { request.id = this.cs.sConfig.id };
        const method = (this.cs.sConfig.id) ? 'patch' : 'post';
        this.loaderService.display(true);
        this._bs.doRequest('/w/company/settings', method, request)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((result: any) => {
            if (result.status) {
                setTimeout(() => {
                    this.loaderService.display(false);
                    this.toast.sToast('success', `Saved successfully!`);
                    this._changeDetectorRef.detectChanges();
                }, 3000);
            } else {
                this.toast.sToast('error', result.message);
            }
        })
    }

    getLayoutSettings(): void {
        if (!this._bs.user().email) {
            setTimeout(() => this.getLayoutSettings(), 1000);
            return;
        }
        const params: any = {
            condition: `category='settings' and value IS NOT NULL and sub_category='layout' and name='${this._bs.user().email}'`,
            order_by: `updated desc`
        }
        this._bs.doRequest(`/r/company/settings`, 'get', null, params)
        .pipe(takeUntil(this._unsubscribeAll))
        .forEach((result: any) => {
            if (result.status && result.data && result.data.length) {
                this.config = result.data[0].value;
                this.cs.sConfig = result.data[0];
                this.setLayout(this.config.layout); this.setScheme(this.config.scheme); this.setTheme(this.config.theme); this.setView(this.config.view);
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    resetSetting(): void {
        if(!this.cs.sConfig || !this.cs.sConfig.id){ return; }
        this.loaderService.display(true);
        this._bs.doRequest(`/d/company/settings/${this.cs.sConfig.id}`, 'delete')
        .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((result: any) => {
                this.loaderService.display(false);
                if (result.status) {
                    this.toast.sToast('success', 'Removed successfully!');
                    this.setLayout('centered'); this.setScheme('dark'); this.setTheme('theme-default');
                } else {
                    this.toast.sToast('error', result.message);
                }
            });
    }
}
