/* eslint-disable */
import { FuseNavigationItem } from "@fuse/components/navigation";
import cloneDeep from "lodash-es/cloneDeep";

export const defaultNavigation: FuseNavigationItem[] = [
  // {
  //   id: "partner-management",
  //   title: "Partner Management",
  //   subtitle: "",
  //   type: "group",
  //   icon: "mat_outline:policy",
  //   api: "partner-management",
  //   link: "/partner-management",
  //   exactMatch: true,
  //   children: [
  //     // {
  //     //   id: "partner-management.service-list",
  //     //   title: "All services",
  //     //   type: "basic",
  //     //   icon: "heroicons_mini:user-group",
  //     //   link: "/partner-management/service-list",
  //     //   // api: "partner-management",
  //     //   exactMatch: true,
  //     // },
  //   ],
  // },
];

const compact: any = cloneDeep(defaultNavigation);
compact.map((x: any) => {
  if (x.children) {
    x.children = [];
    x.type = "aside";
  } else {
    x.type = "basic";
  }
});
export const compactNavigation: FuseNavigationItem[] = compact;

export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;

const horizontal: any = cloneDeep(defaultNavigation);
horizontal.map((x: any) => {
  x.children ? (x.children = []) : null;
});
export const horizontalNavigation: FuseNavigationItem[] = horizontal;
