import {NgForOf, NgIf, UpperCasePipe} from '@angular/common';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {ActivatedRoute, NavigationEnd, Route, Router, RouterOutlet} from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseHorizontalNavigationComponent, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';

import { SearchComponent } from 'app/layout/common/search/search.component';

import { UserComponent } from 'app/layout/common/user/user.component';
import {filter, Subject, Subscription, takeUntil} from 'rxjs';
import {ScopeComponent} from "../../../common/scope/scope.component";
import {DynamicSettingsComponent} from "../../../common/dynamic-settings/dynamic-settings.component";
import {CommonService} from "../../../../_services/common.service";
import {SkeletonComponent} from "../../../../modules/shared/skeleton/skeleton.component";
import {BaseRequestService} from "../../../../_services/base.service";

@Component({
    selector     : 'modern-layout',
    templateUrl  : './modern.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone   : true,
    imports: [FuseLoadingBarComponent, NgIf, FuseVerticalNavigationComponent, FuseHorizontalNavigationComponent, MatButtonModule, MatIconModule, LanguagesComponent, FuseFullscreenComponent, SearchComponent, MessagesComponent, NotificationsComponent, UserComponent, RouterOutlet, ScopeComponent, DynamicSettingsComponent, SkeletonComponent, UpperCasePipe, NgForOf],
})
export class ModernLayoutComponent implements OnInit, OnDestroy
{
    @ViewChild('_wrapper_', { static: false }) _wrapper_: ElementRef;
    isScreenSmall: boolean;
    navigation: Navigation;
    _overall_navigation: Navigation;
    navHash: any = {};
    currentPath: any;

    private subs: Subscription;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public _bs: BaseRequestService,
        public _cs: CommonService
    ) {
        this.subs = this._cs.selectedSiteChanged.subscribe((cmp: any) => {
            console.log(cmp);
            this.initNav(cmp);
        });
        _router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe((val: any) => {
            console.log(val);
            this.currentPath = val.url.split('/');
        });
        this.getNavLinks(this._router.config);
        setTimeout(() => {
            try {
                if (this._activatedRoute.snapshot['_routerState']) {
                    this.currentPath = this._activatedRoute.snapshot['_routerState'].url?.split('/');
                }
            } catch (e) {
                setTimeout(() => {
                    this.currentPath = this._activatedRoute.snapshot['_routerState'].url?.split('/');
                });
            }
        });
    }

    getNavLinks(routes: Route[]) {
        routes.forEach((x) => {
          if(x.children) {
            this.getNavLinks(x.children);
          }
          if (x.data && x.data.breadcrumb) {
              this.navHash[x.path] = x.data.breadcrumb.alias;
          }
        });
    }

    /*{
        this._cs.captureScreenChange.subscribe((res: any) => {
            this.cs.captureScreen(this._wrapper_.nativeElement);
        })
    }*/

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.initNav();
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) =>
            {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) =>
            {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    initNav(filter?: any): void {
        this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll)).subscribe((navigation: Navigation) => {
            if (!this._overall_navigation) {
                this._overall_navigation = navigation;
            }
            const navi = Object.assign({}, this._overall_navigation);
            if (filter === '*') {
                Object.keys(navi).forEach((obj: any) => {
                    navi[obj] = navi[obj].filter((i: any) => i.id !== 'activedirectory');
                });
            }
            this.navigation = navi;
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
