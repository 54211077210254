import {
  ChangeDetectorRef,
  Component,
  ViewEncapsulation,
} from "@angular/core";
import {
  CommonModule,
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButton, MatButtonModule } from "@angular/material/button";
import { FuseScrollbarDirective } from "../../../../@fuse/directives/scrollbar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MaterialModule } from "../../../material.module";
import { FuseDrawerComponent } from "../../../../@fuse/components/drawer";
import { SettingsViewComponent } from "./settings-view/settings-view.component";
import { SettingsComponent } from "../settings/settings.component";
import { FuseAlertComponent } from "@fuse/components/alert";
import { DynamicFormComponent } from "app/modules/shared/forms/dynamic-form/dynamic-form.component";
import { DirectivesModule } from "app/-directives/-directives.module";
import { GettingStartedComponent } from "app/modules/shared/getting-started/getting-started.component";

@Component({
  selector: "dynamic-settings",
  templateUrl: "./dynamic-settings.component.html",
  styleUrls: ["./dynamic-settings.component.scss"],
  encapsulation: ViewEncapsulation.None,
  exportAs: "dynamicSettings",
  standalone: true,
  imports: [
    CommonModule,
    GettingStartedComponent,
    DirectivesModule,
    SettingsViewComponent,
    NgClass,
    NgIf,
    MatIconModule,
    MatButtonModule,
    FuseScrollbarDirective,
    NgFor,
    NgTemplateOutlet,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    DatePipe,
    MaterialModule,
    FuseAlertComponent,
    FuseDrawerComponent,
    NgOptimizedImage,
    SettingsComponent,
    DynamicFormComponent,
  ],
})
export class DynamicSettingsComponent{

}
