<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <img
                    class="w-24"
                    [src]="_cs.logo" aria-label="dark"
                    alt="Logo image">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div #_wrapper_ id="_wrapper_" class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-14 sm:h-14 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <img
                        class="dark:hidden  w-[12rem]"
                        [src]="_cs.logo" aria-label="light">
                    <img
                        class="hidden dark:flex  w-[12rem]"
                        [src]="_cs.logo" aria-label="dark">
                </div>
                <img
                    class="flex lg:hidden w-50"
                    [src]="_cs.logo" aria-label="logo">
            </div>
            <!-- Horizontal navigation -->
            <fuse-horizontal-navigation
                class="mr-2"
                [name]="'mainNavigation'"
                [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
            <!--<new-company></new-company>-->
            <scope></scope>
            <button
                        class="lg:hidden"
                        mat-icon-button
                        (click)="dynamicSettings.toggle()">
                        <mat-icon [svgIcon]="'heroicons_outline:adjustments-horizontal'"></mat-icon>
                    </button>
            <!--<messages></messages>-->
            <!--<notifications></notifications>-->
            <!--<button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button>-->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <!--<router-outlet *ngIf="true"></router-outlet>-->
        <div class="flex flex-row items-center gap-2 text-gray-400 px-6 pt-4" *ngIf="currentPath && currentPath.length">
            <i class="fas fa-home"></i> <span *ngFor="let s of currentPath; let last = last"> {{navHash[s]|uppercase}} <i *ngIf="!last" class="ml-1 fas fa-chevron-right"></i></span>
        </div>
        <router-outlet *ngIf="_bs.user().email && !_cs.isComLoading">

        </router-outlet>
        <app-skeleton *ngIf="_cs.isComLoading">

        </app-skeleton>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">ConnectSecure &copy; {{currentYear}}</span>
    </div>

</div>

<dynamic-settings #dynamicSettings="dynamicSettings"></dynamic-settings>
