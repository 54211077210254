import { Route } from "@angular/router";
import { initialDataResolver } from "app/app.resolvers";
import { AuthGuard } from "app/core/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/Services'
  {
    path: "",
    pathMatch: "full",
    redirectTo: "Services",
  },

  // Auth routes for guests
  {
    path: "",
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "",
        redirectTo: "Services", // Redirect to Services if user is not signed in
        pathMatch: "full",
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-out",
        loadChildren: () => import("app/modules/auth/sign-out/sign-out.routes"),
      },
      {
        path: "Services",
        loadChildren: () => import("app/modules/admin/service-list.routes"),
      },
    ],
  },

  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard], // Assuming only authenticated users can access admin routes
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    // children: [
    //   {
    //     path: "Services",
    //     data: { breadcrumb: { alias: "Pod Management" } },
    //     loadChildren: () =>
    //                 import("app/modules/admin/service-list.routes"),
    //     runGuardsAndResolvers: "always",
    //   },
    // ],
  },
  {
    path: "**",
    redirectTo: "Services",
  },
];

